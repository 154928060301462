import React, { useState } from 'react';
import RegisterForm from './RegisterForm';
import SignInForm from './SignInForm';
import './RegistrationSignInModal.css';

function RegistrationSignInModal({ isOpen, onClose, onSignIn }) {
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [showSignInForm, setShowSignInForm] = useState(false);

    const handleRegisterClick = () => {
        setShowRegisterForm(true);
        setShowSignInForm(false);
    };

    const handleSignInClick = () => {
        setShowRegisterForm(false);
        setShowSignInForm(true);
    };

    const handleSuccess = () => {
        onSignIn(); // Trigger the onSignIn callback from Checkout
    };

    return (
        isOpen && (
            <div className="registration-signin-modal">
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="close-modal" onClick={onClose}></button>
                        <div className="modal-body">
                            <div className="registration-info">
                                <h3>Welcome!</h3>
                                <p>If you are new, register here:</p>
                                <button className="register-btn" onClick={handleRegisterClick}>Register</button>
                                <br></br>
                                <br></br>
                                <br></br>
                                <p>If you already have an account, sign in:</p>
                                <button className="sign-in-btn" onClick={handleSignInClick}>Sign In</button>
                                <br></br>
                                <br></br>
                                <br></br>
                                <p>Creating an account is quick — just choose a username and password!</p>
                            </div>
                            <br />
                            {showRegisterForm && <RegisterForm onSuccess={handleSuccess} />}
                            {showSignInForm && <SignInForm onSuccess={handleSuccess} />}
                        </div>
                    </div>
                </div>
            </div>
        )
    );
}

export default RegistrationSignInModal;
