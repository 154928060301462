import React, { useEffect, useState } from 'react';
import './Kitchen.css'; // Import the updated CSS

function Kitchen() {
    const [orders, setOrders] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [updatingOrder, setUpdatingOrder] = useState(null);
    const [selectedDriver, setSelectedDriver] = useState({});

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    useEffect(() => {
        fetchOrders();
        fetchDrivers();

        const interval = setInterval(fetchOrders, 30000);
        return () => clearInterval(interval);
    }, []); // Empty dependency array

    function fetchOrders() {
        setLoading(true);
        fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders')
            .then(response => response.json())
            .then(data => {
                setOrders(data || []);
                alertNewOrders(data || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
                setError('Failed to fetch orders.');
                setLoading(false);
            });
    }

    function fetchDrivers() {
        fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/drivers')
            .then(response => response.json())
            .then(data => setDrivers(data || []))
            .catch(error => console.error('Error fetching drivers:', error));
    }

    function alertNewOrders(orders) {
        const newOrders = (orders || []).filter(order => order.status === "Pending");
        if (newOrders.length > 0) {
            alert("New orders received! OR There are new orders pending!");
        }
    }

    function updateOrderWithDriver(orderId, driverId) {
        setUpdatingOrder(orderId);

        fetch(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders/${orderId}/assign-driver`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ driverId }),
        })
            .then(response => response.json())
            .then(() => {
                fetchOrders(); // Refresh the orders list
                setUpdatingOrder(null); // Clear the updating state
            })
            .catch(error => {
                console.error('Error updating order with driver:', error);
                setError('Failed to update order with driver.');
                setUpdatingOrder(null); // Clear the updating state
            });
    }

    function updateStatus(orderId, newStatus) {
        setUpdatingOrder(orderId);

        fetch(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders/${orderId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ status: newStatus }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to update status');
                }
                return response.json();
            })
            .then(data => {
                // Log the updated order for debugging
                console.log('Order updated successfully:', data);
                fetchOrders(); // Refresh the orders list
                setUpdatingOrder(null); // Clear the updating state
            })
            .catch(error => {
                console.error('Error updating order status:', error);
                setError('Failed to update order status.');
                setUpdatingOrder(null); // Clear the updating state
            });
    }

    function handleDriverChange(orderId, driverId) {
        setSelectedDriver(prevState => ({ ...prevState, [orderId]: driverId }));
    }

    function handleAllocateClick(orderId) {
        const driverId = selectedDriver[orderId];
        if (driverId) {
            updateOrderWithDriver(orderId, driverId);
            setSelectedDriver(prevState => ({ ...prevState, [orderId]: null }));
        } else {
            alert('Please select a driver before allocating.');
        }
    }

    function handleStatusUpdateClick(orderId, newStatus) {
        updateStatus(orderId, newStatus);
    }

    function renderOrdersByStatus(status) {
        const filteredOrders = (orders || [])
            .filter(order => order.status === status)
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        return (
            <div className="order-section" key={status}>
                <h3 className="status-heading">{status}</h3>
                <ul className="order-list">
                    {filteredOrders.map(order => {
                        const assignedDriver = drivers.find(driver => driver.id === order.driverId);
                        const isDriverAssigned = !!order.driverId;
                        return (
                            <li key={order.id} className="order-item">
                                <p><strong>Order ID:</strong> {order.id}</p>
                                <p><strong>Ordered Time: </strong>{formatDate(order.createdAt)}</p>
                                <p><strong>Status:</strong> {order.status}</p>
                                <p><strong>Items:</strong> {order.items.map(item => `${item.name} - ${item.variant.name} - ${item.variant.size} x${item.quantity}`).join(' || ')}</p>
                                <p><strong>Total price:</strong> R{order.total}</p>
                                <p><strong>Payment Method:</strong> {order.paymentMethod}</p>
                                <p><strong>Delivery Address:</strong> {order.deliveryAddress ? `${order.deliveryAddress.streetNumber} ${order.deliveryAddress.streetName}, ${order.deliveryAddress.suburb}, ${order.deliveryAddress.postalCode}` : 'N/A'}</p>
                                <p><strong>Customer name:</strong> {order.deliveryAddress.name}</p>
                                <p><strong>Customer number:</strong> {order.deliveryAddress.phone}</p>
                                <p><strong>Delivery Time Slot:</strong> {order.deliveryAddress.deliveryTime}</p>
                                <p><strong>Delivery Instructions:</strong> {order.deliveryAddress.deliveryNote}</p>

                                {status === "Pending" && (
                                    <button
                                        onClick={() => handleStatusUpdateClick(order.id, "In Progress")}
                                        disabled={updatingOrder === order.id}
                                    >
                                        {updatingOrder === order.id ? "Updating..." : "Update Status to In Progress"}
                                    </button>
                                )}

                                {status === "In Progress" && (
                                    <button
                                        onClick={() => handleStatusUpdateClick(order.id, "Ready for Delivery")}
                                        disabled={updatingOrder === order.id}
                                    >
                                        {updatingOrder === order.id ? "Updating..." : "Update Status to Ready for Delivery"}
                                    </button>
                                )}

                                {status === "Ready for Delivery" && !isDriverAssigned && (
                                    <>
                                        <label htmlFor={`driver-select-${order.id}`}>Assign Driver:</label>
                                        <select
                                            id={`driver-select-${order.id}`}
                                            value={selectedDriver[order.id] || ''}
                                            onChange={e => handleDriverChange(order.id, e.target.value)}
                                        >
                                            <option value="">Select a driver</option>
                                            {(drivers || []).map(driver => (
                                                <option key={driver.id} value={driver.id}>
                                                    {driver.name}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            onClick={() => handleAllocateClick(order.id)}
                                            disabled={updatingOrder === order.id}
                                        >
                                            {updatingOrder === order.id ? "Allocating..." : "Allocate to Driver"}
                                        </button>
                                    </>
                                )}

                                {status === "Ready for Delivery" && isDriverAssigned && (
                                    <p><strong>Assigned Driver:</strong> {assignedDriver ? assignedDriver.name : 'Unknown'}</p>
                                )}

                                {status === "Delivered" && (
                                    <p><strong>Assigned Driver:</strong> {assignedDriver ? assignedDriver.name : 'Unknown'}</p>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    return (
        <div className="kitchen-orders">
            <h2>Kitchen Orders</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p className="error-message">{error}</p>
            ) : (
                <>
                    {renderOrdersByStatus("Pending")}
                    {renderOrdersByStatus("In Progress")}
                    {renderOrdersByStatus("Ready for Delivery")}
                </>
            )}
        </div>
    );
}

export default Kitchen;
