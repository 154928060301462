import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useHistory, useLocation, Link } from 'react-router-dom';
import './SignInForm.css';

function SignInForm({ onSuccess }) {
    const { signIn } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(true);
    const [error, setError] = useState('');
    const history = useHistory();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await signIn(username, password);
            if (result.token) {
                // Notify parent component of successful sign-in
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }

                // Redirect based on the current location
                const redirectTo = location.pathname === '/checkout' ? '/checkout' : '/';
                history.push(redirectTo);
            } else {
                setError('Invalid credentials. Please try again.');
            }
        } catch (error) {
            console.error('Failed to sign in', error);
            setError('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="sign-in-container">
            <form onSubmit={handleSubmit}>
                <h2>Sign In</h2>
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <div className="password-container">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? 'Hide' : 'Show'}
                    </button>
                </div>
                {error && <div className="error-message">{error}</div>}
                <button type="submit" className="sign-in-btn">Sign In</button>
            </form>
            <p>New here? Sign up today 
                <Link to="/register" className="register-btn">Register</Link>
            </p>
        </div>
    );
}

export default SignInForm;
