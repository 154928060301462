import React, { useContext } from 'react';
import { BasketContext } from '../contexts/BasketContext';
import { useHistory } from 'react-router-dom';
import './FrontPageBasket.css';
import { FaShoppingCart } from 'react-icons/fa'; // Import a cart icon

function FrontPageBasket() {
    const { basket, total } = useContext(BasketContext);
    const history = useHistory();

    const handleClick = () => {
        history.push('/checkout');
    };

    return (
        <div className="frontpagebasket" onClick={handleClick}>
            <div className="frontpagebasket-summary">
                <FaShoppingCart className="icon" /> {/* Add the cart icon */}
                <p>{basket.length} items</p>
                <p>Total: R{total}</p>
            </div>
        </div>
    );
}

export default FrontPageBasket;



