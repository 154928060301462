import React from 'react';
import './ContactUs.css';

function ContactUs() {
    return (
        <div className="contact-us">
            <h2>Contact Us</h2>
            <section>
                <p>
                    We’d love to hear from you! For any inquiries, feedback, or just to say hello, feel free to reach out to us.
                </p>
                <p>
                    You can contact us via email at: 
                    <strong> lilly.govender2907@gmail.com</strong>
                </p>
                <p>
                    Alternatively, feel free to give us a call on: 
                    <strong> 0848114260</strong>
                </p>
                <p>We are here to assist you and ensure you have the best experience possible.</p>
            </section>

            {/* Google Rating Section */}
            <section className="rate-us">
                <h3>Rate Us on Google</h3>
                <p>
                We would greatly appreciate it if you could take a moment to leave us a review on Google. Your feedback helps us improve and continue delivering exceptional service!
                </p>
                <a
                    href="https://g.page/r/CWUOuJ4RvwaUEBM/review"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="google-rate-btn"
                >
                    Rate Us on Google
                </a>
            </section>
        </div>
    );
}

export default ContactUs;
