import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('token');
            if (token) {
                try {
                    const response = await fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/users/check', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    const data = await response.json();
                    if (data.isAuthenticated) {
                        setIsAuthenticated(true);
                        setUser(data.user);
                    } else {
                        setIsAuthenticated(false);
                    }
                } catch (err) {
                    console.error(err);
                    setIsAuthenticated(false);
                }
            }
        };
        checkAuth();
    }, []);

    const register = async (username, password) => {
        try {
            const response = await fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/users/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });
            const data = await response.json();
    
            if (data.user) {
                // Automatically sign in the user after successful registration
                const signInResponse = await signIn(username, password);
                if (signInResponse.token) {
                    setIsAuthenticated(true);
                    setUser({ username, id: data.user.id });
                    localStorage.setItem('token', signInResponse.token);
                    return { success: true }; // Return success if the sign-in process worked
                }
            }
            return { error: data.message || 'Registration failed' };
        } catch (err) {
            console.error(err);
            return { error: 'An error occurred during registration' };
        }
    };
    

    const signIn = async (username, password) => {
        try {
            const response = await fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/users/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password })
            });
            const data = await response.json();
            if (data.token) {
                setIsAuthenticated(true);
                setUser({ username, id: data.userId, role: data.role });
                localStorage.setItem('token', data.token);
            }
            return data;
        } catch (err) {
            console.error(err);
            return { error: err.message };
        }
    };

    const signOut = async () => {
        try {
            await fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/users/logout', { method: 'POST' });
            setIsAuthenticated(false);
            setUser(null);
            localStorage.removeItem('token');
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, register, signIn, signOut }}>
            {children}
        </AuthContext.Provider>
    );
};
