import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import './HamburgerMenu.css';

function HamburgerMenu() {
    const [isOpen, setIsOpen] = useState(false);
    const { isAuthenticated, signOut, user } = useContext(AuthContext); // Access user object from AuthContext

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleSignOut = () => {
        signOut();
        setIsOpen(false);
    };

    return (
        <div className="hamburger-menu">
            <div className={`hamburger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
            {isOpen && (
                <div className="dropdown">
                    <Link to="/orders" onClick={() => setIsOpen(false)}>My Orders</Link>
                    {/* {isAuthenticated ? (
                        <span onClick={handleSignOut}>Sign Out</span>
                    ) : (
                        <Link to="/sign-in" onClick={() => setIsOpen(false)}>Sign In</Link>
                    )} */}
                    <Link to="/contact-us" onClick={() => setIsOpen(false)}>Contact Us</Link>
                    <Link to="/about-us" onClick={() => setIsOpen(false)}>About Us</Link>
                        {/* Show admin-specific links if the user is authenticated and has the admin role */}
                        {isAuthenticated && user?.role === 'admin' && (
                            <>
                            <Link to="/kitchen" onClick={() => setIsOpen(false)}>Kitchen</Link>
                            <Link to="/driver-orders" onClick={() => setIsOpen(false)}>Driver Orders</Link>
                            </>
                        )}
                         {isAuthenticated && user?.role === 'superadmin' && (
                            <>
                            <Link to="/kitchen" onClick={() => setIsOpen(false)}>Kitchen</Link>
                            <Link to="/driver-orders" onClick={() => setIsOpen(false)}>Driver Orders</Link>
                            <Link to="/update-menu" onClick={() => setIsOpen(false)}>Update Menu</Link>
                            </>
                        )}
                </div>
            )}
        </div>
    );
}

export default HamburgerMenu;
