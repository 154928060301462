import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link } from 'react-router-dom'; // Assuming you are using react-router for navigation
import './Orders.css'; // Add necessary styling

const Orders = () => {
    const { user } = useContext(AuthContext);
    const [orders, setOrders] = useState([]);
    const [showCompleted, setShowCompleted] = useState(false);

    // Define a mapping for status replacement and class names
    const statusMapping = {
        Pending: { message: 'Order placed successfully', className: 'status-pending' },
        'In Progress': { message: 'Your order is being prepared', className: 'status-in-progress' },
        'Ready for Delivery': { message: 'The kitchen is just wrapping up', className: 'status-ready' },
        'On the Way': { message: 'On the Way', className: 'status-on-the-way' },
        Delivered: { message: 'Delivered', className: 'status-delivered' },
    };

    useEffect(() => {
        let intervalId;

        if (user && user.id) {
            const fetchOrders = async () => {
                try {
                    const response = await fetch(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders/user/${user.id}`);
                    if (!response.ok) {
                        throw new Error('Failed to fetch orders');
                    }
                    const data = await response.json();
                    setOrders(data);
                } catch (error) {
                    console.error('Error fetching orders:', error);
                }
            };

            // Fetch orders immediately on component mount
            fetchOrders();

            // Set up polling every 60 seconds (1 minute)
            intervalId = setInterval(() => {
                fetchOrders();
            }, 60000); // 60000ms = 60s

            // Clean up the interval when the component unmounts
            return () => clearInterval(intervalId);
        }
    }, [user]);

    // If user is not signed in, show a prompt to sign in
    if (!user) {
        return (
            <div className="orders-page">
                <h3>You need to sign in to view your orders</h3>
                <Link to="/sign-in">
                    <button className="sign-in-button">Sign In</button>
                </Link>
            </div>
        );
    }

    const activeOrders = orders.filter(order => order.status !== 'Delivered');
    const completedOrders = orders.filter(order => order.status === 'Delivered');

    return (
        <div className="orders-page">
            <h2>Your Orders</h2>
            <div className="active-orders">
                <h3>Current Orders</h3>
                {activeOrders.length > 0 ? (
                    activeOrders.map(order => (
                        <OrderItem key={order.id} order={order} statusMapping={statusMapping} />
                    ))
                ) : (
                    <p>No active orders.</p>
                )}
            </div>
            <br></br>
            <button className="toggle-button" onClick={() => setShowCompleted(!showCompleted)}>
                {showCompleted ? 'Hide Completed Orders' : 'Show Completed Orders'}
            </button>
            {showCompleted && (
                <div className="completed-orders">
                    <h3>Completed Orders</h3>
                    {completedOrders.length > 0 ? (
                        completedOrders.map(order => (
                            <OrderItem key={order.id} order={order} statusMapping={statusMapping} />
                        ))
                    ) : (
                        <p>No completed orders.</p>
                    )}
                </div>
            )}
        </div>
    );
};

const formatDate = (isoString) => {
    const date = new Date(isoString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const OrderItem = ({ order, statusMapping }) => {
    const statusSteps = ['Pending', 'In Progress', 'Ready for Delivery', 'On the Way', 'Delivered'];
    
    const isCompleted = order.status === 'Delivered';

    const getEstimatedDeliveryTime = (createdAt) => {
        if (!createdAt) return 'Calculating...';
        const createdDate = new Date(createdAt);
        const oneHourInMilliseconds = 3600000;
        const estimatedDeliveryDate = new Date(createdDate.getTime() + oneHourInMilliseconds);
        return estimatedDeliveryDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const { message, className } = statusMapping[order.status] || { message: '', className: '' };

    return (
        <div className="order-item">
            <h4>Order #{order.id}</h4>
            {!isCompleted && (
                <>
                    <p><strong>Amount to pay on delivery:</strong> R{order.total}</p>
                    {order?.deliveryAddress.deliveryTime && order.deliveryAddress.deliveryTime !== "ASAP" ? (
                        <p><strong>Delivery time slot: </strong>{order.deliveryAddress.deliveryTime}</p> 
                    ) : (
                        <p><strong>Estimated delivery time: </strong>{getEstimatedDeliveryTime(order?.createdAt)}</p>
                    )}
                </>
            )}
            <p><strong>Ordered Date: </strong>{formatDate(order.createdAt)}</p>
            <p><strong>Delivery Address: </strong>{order.deliveryAddress.streetNumber} {order.deliveryAddress.streetName}, {order.deliveryAddress.suburb}, {order.deliveryAddress.postalCode}</p>
            <h4>Items:</h4>
            <ul>
                {order.items.map((item, index) => (
                    <li key={index}>
                        {item.name} - {item.variant.name} - {item.variant.size} x {item.quantity}
                    </li>
                ))}
            </ul>
            <div className={`order-status ${className}`}>
                {!isCompleted ? (
                    statusSteps.map((step, index) => {
                        const stepMessage = statusMapping[step]?.message || step;
                        return (
                            <div key={index} className="status-step">
                                <div
                                    className={`status-circle ${getStatusStep(order.status) >= index ? 'completed' : ''}`}
                                >
                                    {index + 1}
                                </div>
                                <span className="status-label">
                                    {stepMessage}
                                    {stepMessage === 'On the Way' && !isCompleted && order.estimatedDeliveryTime && (
                                        <span className="estimated-delivery-time">
                                            || ETA: {order.estimatedDeliveryTime} minutes
                                        </span>
                                    )}
                                </span>
                            </div>
                        );
                    })
                ) : (
                    <div className="completed-status">
                        <div className="status-circle completed">✔</div>
                        <span className="status-label">{message}</span>
                    </div>
                )}
            </div>
            {isCompleted && <div className="completed-details"></div>}
        </div>
    );
};

const getStatusStep = (status) => {
    const steps = ['Pending', 'In Progress', 'Ready for Delivery', 'On the Way', 'Delivered'];
    return steps.indexOf(status);
};

export default Orders;
