import React, { useEffect, useState, useCallback } from 'react';
import './DriverOrders.css'; // Import the CSS for styling

function DriverOrders() {
    const [orders, setOrders] = useState([]);
    const [updatingOrder, setUpdatingOrder] = useState(null);
    const [selectedTime, setSelectedTime] = useState({});
    const [paymentRef, setPaymentRef] = useState("");
    const [failureReason, setFailureReason] = useState("");
    const [driverId, setDriverId] = useState("");
    const [drivers, setDrivers] = useState([]);
    const [showDeliveredOrders, setShowDeliveredOrders] = useState(false); // State to control visibility of delivered orders

    useEffect(() => {
        fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/drivers')
            .then(response => response.json())
            .then(data => {
                setDrivers(data);
                if (data.length > 0) {
                    setDriverId(data[0].id); // Set the default driver
                }
            })
            .catch(error => {
                console.error('Error fetching drivers:', error);
            });
    }, []);

    const fetchDriverOrders = useCallback(() => {
        if (driverId) {
            fetch(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders/driver/${driverId}`)
                .then(response => response.json())
                .then(data => {
                    setOrders(data);
                })
                .catch(error => {
                    console.error('Error fetching driver orders:', error);
                });
        }
    }, [driverId]);

    useEffect(() => {
        fetchDriverOrders();
        const interval = setInterval(fetchDriverOrders, 30000);
        return () => clearInterval(interval);
    }, [fetchDriverOrders]);

    function updateOrderStatus(orderId, status, extraData = {}) {
        setUpdatingOrder(orderId);
        fetch(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders/${orderId}/status`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ status, ...extraData }),
        })
        .then(response => response.json())
        .then(() => {
            fetchDriverOrders();
            setUpdatingOrder(null);
        })
        .catch(error => {
            console.error('Error updating order status:', error);
            setUpdatingOrder(null);
        });
    }

    function handleOnTheWay(orderId) {
        const estimatedTime = selectedTime[orderId];
        if (estimatedTime) {
            updateOrderStatus(orderId, "On the Way", { estimatedTime });
        } else {
            alert("Please select an estimated delivery time.");
        }
    }

    function handleSuccess(orderId) {
        updateOrderStatus(orderId, "Delivered", { paymentRef });
    }

    function handleFailure(orderId) {
        if (failureReason) {
            updateOrderStatus(orderId, "Failed", { failureReason });
        } else {
            alert("Please provide a reason for the failure.");
        }
    }

    const groupedOrders = {
        "On the Way": orders.filter(order => order.status === "On the Way"),
        "Ready for Delivery": orders.filter(order => order.status === "Ready for Delivery"),
        "Delivered": orders.filter(order => order.status === "Delivered"),
        "Failed": orders.filter(order => order.status === "Failed"),
    };

    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    function formatAddress(address) {
        if (typeof address === 'object') {
            const {
                addressType,
                streetNumber,
                streetName,
                suburb,
                postalCode,
                apartmentName,
                unitNumber,
                businessName,
                businessEstate,
            } = address;
            return [
                addressType,
                streetNumber && `${streetNumber} ${streetName}`,
                suburb,
                postalCode,
                apartmentName && `Apartment: ${apartmentName}`,
                unitNumber && `Unit: ${unitNumber}`,
                businessName && `Business: ${businessName}`,
                businessEstate && `Estate: ${businessEstate}`,
            ].filter(Boolean).join(', ');
        }
        return address || 'No address provided';
    }

    return (
        <div className="driver-orders">
            <div className="driver-selection">
                <label htmlFor="driverSelect">Select Driver:</label>
                <select
                    id="driverSelect"
                    onChange={(e) => setDriverId(e.target.value)}
                    value={driverId}
                >
                    {drivers.map(driver => (
                        <option key={driver.id} value={driver.id}>{driver.name}</option>
                    ))}
                </select>
            </div>
            <h2>Assigned Orders</h2>
            {Object.keys(groupedOrders).filter(status => status !== "Delivered").map(status => (
                <div key={status}>
                    <h3>{status}</h3>
                    {groupedOrders[status].length === 0 ? (
                        <p>No orders</p>
                    ) : (
                        groupedOrders[status].map(order => (
                            <div key={order.id} className="order-card">
                                <p><strong>Order ID:</strong> {order.id}</p>
                                <p><strong>Customer:</strong> {order.customerName}</p>
                                <p><strong>Address:</strong> {formatAddress(order.deliveryAddress)}</p>
                                <p><strong>Delivery Instructions:</strong> {order.deliveryAddress.deliveryNote}</p>
                                <p><strong>Delivery Time Slot:</strong> {order.deliveryAddress.deliveryTime}</p>
                                <p><strong>Status:</strong> {order.status}</p>
                                <div className="order-actions">
                                    {order.status === "Ready for Delivery" && (
                                        <>
                                            <select
                                                value={selectedTime[order.id] || ""}
                                                onChange={(e) => setSelectedTime({ ...selectedTime, [order.id]: e.target.value })}
                                            >
                                                <option value="">Select estimated time</option>
                                                {[5, 10, 15, 20, 25, 30].map(time => (
                                                    <option key={time} value={time}>{time} minutes</option>
                                                ))}
                                            </select>
                                            <button onClick={() => handleOnTheWay(order.id)} disabled={updatingOrder === order.id}>
                                                {updatingOrder === order.id ? "Updating..." : "On the Way"}
                                            </button>
                                        </>
                                    )}
                                    {order.status === "On the Way" && (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Payment Reference"
                                                value={paymentRef}
                                                onChange={(e) => setPaymentRef(e.target.value)}
                                            />
                                            <button onClick={() => handleSuccess(order.id)} disabled={updatingOrder === order.id}>
                                                {updatingOrder === order.id ? "Updating..." : "Success"}
                                            </button>
                                            <button onClick={() => handleFailure(order.id)} disabled={updatingOrder === order.id}>
                                                {updatingOrder === order.id ? "Updating..." : "Failure"}
                                            </button>
                                        </>
                                    )}
                                    {order.status === "Failed" && (
                                        <textarea
                                            placeholder="Reason for Failure"
                                            value={failureReason}
                                            onChange={(e) => setFailureReason(e.target.value)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))
                    )}
                </div>
            ))}

            {/* Toggle for Delivered Orders */}
            <div>
                <button className="toggle-button" onClick={() => setShowDeliveredOrders(!showDeliveredOrders)}>
                    {showDeliveredOrders ? "Hide Delivered Orders" : "Show Delivered Orders"}
                </button>
                {showDeliveredOrders && groupedOrders["Delivered"].length > 0 && (
                    <div>
                        <h3>Delivered Orders</h3>
                        {groupedOrders["Delivered"].map(order => (
                            <div key={order.id} className="order-card">
                                <p><strong>Order ID:</strong> {order.id}</p>
                                <p><strong>Ordered Date: </strong>{formatDate(order.createdAt)}</p>
                                <p><strong>Address:</strong> {formatAddress(order.deliveryAddress)}</p>
                                <p><strong>Status:</strong> {order.status}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default DriverOrders;
