import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BasketContext } from '../contexts/BasketContext';
import { AuthContext } from '../contexts/AuthContext';
import HamburgerMenu from './HamburgerMenu';
import './Navbar.css';

function Navbar() {
    const { total } = useContext(BasketContext);
    const { isAuthenticated, signOut, user } = useContext(AuthContext);

    return (
        <nav className="navbar-container">
            <HamburgerMenu /> {/* Add the HamburgerMenu here */}
            <div className="navbar-logo-container">
                <Link to="/">
                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" />
                </Link>
                <Link to="/">
                    <span className="navbar-title">Divine Veg on 262</span>
                </Link>
            </div>
            <div className="navbar-links-container">
                <Link to="/about-us">About Us</Link>
                <Link to="/contact-us">Contact Us</Link>
                {/* Show admin-specific links if the user is authenticated and has the admin role */}
                {isAuthenticated && user?.role === 'admin' && (
                    <>
                        <Link to="/kitchen">Kitchen</Link>
                        <Link to="/driver-orders">Driver Orders</Link>
                    </>
                )}
            </div>
            <div className="navbar-actions-container">
                {isAuthenticated ? (
                    <button className="sign-in-button" onClick={signOut}>Sign Out</button>
                ) : (
                    <Link to="/sign-in" className="sign-in-button">Sign In</Link>
                )}
                <div className="basket-icon-container">
                    <Link to="/checkout">
                        <span className="basket-count-text">Basket: R{total}</span>
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
