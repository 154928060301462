import React, { useContext , useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Carousel from './components/Carousel';
import Menu from './components/Menu';
import Orders from './components/Orders';
import FrontPageBasket from './components/FrontPageBasket';
import Checkout from './components/Checkout';
import OrderConfirmation from './components/OrderConfirmation';
import Kitchen from './components/Kitchen';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import SignInForm from './components/SignInForm';
import RegisterForm from './components/RegisterForm';
import Footer from './components/Footer';
import Section from './components/Section';
import DriverOrders from './components/DriverOrders';
import UpdateMenuPage from './components/UpdateMenuPage';
import NotificationBanner from './components/NotificationBanner';
import TermsAndConditions from './components/TermsAndConditions';
import { BasketProvider, BasketContext } from './contexts/BasketContext';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import TagManager from 'react-gtm-module';
import './App.css';

const tagManagerArgs = {
  gtmId: 'GTM-568494WZ',
};

TagManager.initialize(tagManagerArgs);

function usePageView() {
    const location = useLocation();

    useEffect(() => {
        // Push a page view event to the GTM dataLayer whenever the route changes
        window.dataLayer.push({
            event: 'pageview',
            pagePath: location.pathname,
            pageTitle: document.title,
        });
    }, [location]);
}

function App() {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/service-worker.js')
                    .then(registration => {
                        console.log('Service Worker registered with scope:', registration.scope);
                    })
                    .catch(error => {
                        console.error('Service Worker registration failed:', error);
                    });
            });
        }
    }, []);

    return (
        <AuthProvider>
            <BasketProvider>
                <Router>
                    <div className="App">
                        <Navbar />
                        <MainContent />
                        <Footer />
                    </div>
                </Router>
            </BasketProvider>
        </AuthProvider>
    );
}

function MainContent() {
    const { isAuthenticated, user } = useContext(AuthContext);
    const location = useLocation();

    // Track page views on route changes
    usePageView();

    return (
        <>
            {location.pathname === '/' && <NotificationBanner />}
            <Switch>
                <Route path="/" exact>
                    <Carousel />
                    <Section />
                    <Menu />
                    <ShowBasketIfNotEmpty />
                </Route>
                <Route path="/checkout" component={Checkout} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/contact-us" component={ContactUs} />
                <Route path="/terms-and-conditions" component={TermsAndConditions} />
                <Route path="/order-confirmation/:orderId?" component={OrderConfirmation}>
                <OrderConfirmation />
                    <NotificationBanner /> {/* Show banner after order confirmation */}
                    </Route>
                <Route path="/sign-in" component={SignInForm} />
                <Route path="/register" component={RegisterForm} /> {/* RegisterForm route */}
                <Route path="/orders" component={Orders} />
                {isAuthenticated && user?.role === 'admin' && (
                    <>
                        <Route path="/kitchen" component={Kitchen} />
                        <Route path="/driver-orders" component={DriverOrders} />
                    </>
                )}
                {isAuthenticated && user?.role === 'superadmin' && (
                    <>
                        <Route path="/kitchen" component={Kitchen} />
                        <Route path="/driver-orders" component={DriverOrders} />
                        <Route path="/update-menu" component={UpdateMenuPage} />
                    </>
                )}
                <Redirect to="/" /> {/* Redirect to home if no match */}
            </Switch>
        </>
    );
}

// Create a separate component to handle showing the basket
function ShowBasketIfNotEmpty() {
    const { basket } = useContext(BasketContext);

    return (
        <>
            {basket.length > 0 && <FrontPageBasket />}
        </>
    );
}

export default App;
