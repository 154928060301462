import React from 'react';
import './AboutUs.css';

function AboutUs() {
    return (
        <div className="about-us">
            <h2>About Divine Veg on 262</h2>
            <section>
                <p>
                    Welcome to Divine Veg on 262, your go-to spot for fresh, delicious vegetarian meals, delivered straight to your door in the Tongaat, Seatides, La Mercy, and Ballito areas.
                </p>
                <p>
                    We are a proud family-owned business, driven by our passion for wholesome vegetarian food and community service. Our carefully crafted menu offers a wide variety of flavorful meals, from daily specials and fast food options to hearty main dishes, sweet desserts, and savory sides. 
                </p>
                <p>
                    At the heart of Divine Veg on 262 is our commitment to giving back. Proceeds from your orders directly support charitable work and uplifting those in need. When you order from us, you're not only enjoying fresh, tasty meals but also contributing to a meaningful cause.
                </p>
                <p>
                    We only started this business in September 2024, and we ask for your patience as we navigate through the early stages. There may be some teething issues, but rest assured, we are working hard to improve every day. Thank you for your support as we grow, and we promise to get better at this!
                </p>
                <p>
                    Thank you for choosing Divine Veg on 262. We look forward to serving you and making a difference together!
                </p>
            </section>
        </div>
    );
}

export default AboutUs;

