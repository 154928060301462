﻿import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { BasketContext } from '../contexts/BasketContext';
import { AuthContext } from '../contexts/AuthContext';
import ConfirmationModal from './ConfirmationModal';
import DeliveryMethodModal from './DeliveryMethodModal';
import RegistrationSignInModal from './RegistrationSignInModal';
import useCheckoutForm from '../hooks/useCheckoutForm';
import './Checkout.css';

const initialAddressValues = {
    name: '',
    phone: '',
    addressType: '',
    streetNumber: '',
    streetName: '',
    suburb: '',
    postalCode: '',
    deliveryNote: '',
    deliveryTime: '',
};

function Checkout() {
    const { basket, removeFromBasket, updateItemQuantity, total, clearBasket } = useContext(BasketContext);
    const [orderStatus, setOrderStatus] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);
    const [showRegistrationSignInModal, setShowRegistrationSignInModal] = useState(false);
    const [itemToRemove, setItemToRemove] = useState(null);
    const [addressConfirmed, setAddressConfirmed] = useState(false);
    const [deliveryFee, setDeliveryFee] = useState(0);
    const [finalTotal, setFinalTotal] = useState(total);
    const [isOrderingAllowed, setIsOrderingAllowed] = useState(true);
    const [deliveryTimeOption, setDeliveryTimeOption] = useState('ASAP'); // ASAP or Scheduled
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [showDeliveryOptions, setShowDeliveryOptions] = useState(true); 
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const addressInputRef = useRef(null);

    const handleDeliveryOptionChange = (e) => {
        setDeliveryTimeOption(e.target.value);
    };

    const {
        values: address,
        errors,
        handleChange,
        handleSubmit,
    } = useCheckoutForm(initialAddressValues);

    useEffect(() => {
        let isMounted = true; // Flag to track if the component is still mounted
    
        const calculateAvailableTimeSlots = () => {
            const now = new Date();
            const availableSlots = [];
    
            // Calculate the start time: round up to the next hour if it's past the current hour
            const startTime = new Date(now.getTime() + 2 * 60 * 60 * 1000); // 2 hours from now
            startTime.setMinutes(0); // Set minutes to 0
            startTime.setSeconds(0); // Set seconds to 0
            startTime.setMilliseconds(0); // Set milliseconds to 0
    
            if (startTime.getHours() < now.getHours() || (startTime.getHours() === now.getHours() && now.getMinutes() > 0)) {
                // If current time is past the next hour, set startTime to next hour + 1
                startTime.setHours(startTime.getHours() + 2);
            }
    
            const endTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 18, 0, 0); // 6 PM today
    
            if (startTime > endTime) {
                if (isMounted) {
                    setAvailableTimeSlots([]); // No scheduled deliveries available
                    setShowDeliveryOptions(false); // Hide delivery options
                }
            } else {
                let currentSlot = new Date(startTime);
                while (currentSlot < endTime) {
                    const nextSlot = new Date(currentSlot.getTime() + 60 * 60 * 1000); // Next hour
                    const slotLabel = `${currentSlot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} to ${nextSlot.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`;
                    availableSlots.push(slotLabel);
                    currentSlot = nextSlot; // Move to the next hour
                }
                if (isMounted) {
                    setAvailableTimeSlots(availableSlots);
                    setShowDeliveryOptions(availableSlots.length > 0); // Show delivery options only if there are available slots
                }
            }
        };
    
        calculateAvailableTimeSlots();
    
        return () => {
            isMounted = false; // Cleanup function to prevent state updates on unmounted component
        };
    }, []);        

    useEffect(() => {
        const checkOrderingHours = () => {
            const now = new Date();
            const day = now.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
            const hour = now.getHours();
            let isWithinOrderingHours = true;

            // Add your logic for ordering hours
            if ((day >= 1 && day <= 5)) { // Weekdays
                isWithinOrderingHours = (hour >= 7 && hour < 17); // Open from 7 AM to 5 PM
            } else { // Weekends
                isWithinOrderingHours = false; // Disable ordering on Weekends
            }
    
            setIsOrderingAllowed(isWithinOrderingHours);
        };
    
        checkOrderingHours();
        const interval = setInterval(checkOrderingHours, 60000); // Check every minute
    
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        console.log('useEffect running');
        const existingScript = document.getElementById('google-maps-script');
        if (!existingScript) {
            const script = document.createElement('script');
            script.id = 'google-maps-script';
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAhsSZ2GSs2gEWJDvWII8RdCT0qYj5l7fA&libraries=places`;
            script.async = true;
            script.onload = () => {
                console.log('Script loaded');
                initializeAutocomplete();
            };
            script.onerror = () => console.error('Failed to load Google Maps script');
            document.head.appendChild(script);
        }
    
        return () => {
            const script = document.getElementById('google-maps-script');
            if (script) {
                console.log('Removing script');
                document.head.removeChild(script);
            }
        };
    }, []);

    const promptForStreetNumber = () => {
        const streetNumber = prompt("Please re-enter just your street number to confirm:", "");
        if (streetNumber) {
            handleChange({ target: { name: 'streetNumber', value: streetNumber } });
        }
    };

    const initializeAutocomplete = () => {
        if (window.google && addressInputRef.current) {
            // Define bounds for KwaZulu-Natal
            const kznBounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(-30.9526, 29.0826), // Southwest corner of KZN
                new window.google.maps.LatLng(-27.3029, 32.9117)  // Northeast corner of KZN
            );

            const autocomplete = new window.google.maps.places.Autocomplete(
                addressInputRef.current,
                { 
                    types: ['address'] ,
                    componentRestrictions: { country: 'za' },  // Restrict to South Africa
                    bounds: kznBounds,  // Restrict to KwaZulu-Natal region
                    strictBounds: true  
                }
            );

            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const addressComponents = place.address_components;
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();

                let streetNumber = '', streetName = '', suburb = '', postalCode = '';

                addressComponents.forEach(component => {
                    const types = component.types;
                    if (types.includes('street_number')) streetNumber = component.long_name;
                    if (types.includes('route')) streetName = component.long_name;
                    if (types.includes('sublocality_level_1') || types.includes('locality')) suburb = component.long_name;
                    if (types.includes('postal_code')) postalCode = component.long_name;
                });

                handleChange({ target: { name: 'streetNumber', value: streetNumber } });
                handleChange({ target: { name: 'streetName', value: streetName } });
                handleChange({ target: { name: 'suburb', value: suburb } });
                handleChange({ target: { name: 'postalCode', value: postalCode } });
                handleChange({ target: { name: 'lat', value: lat } });
                handleChange({ target: { name: 'lng', value: lng } });

                // Prompt the user if the street number is missing
                if (!streetNumber) {
                    promptForStreetNumber();
                }
            });
        }
    };

    const calculateDeliveryFee = (address) => {
        const deliveryRadius = 15;
        const restaurantLocation = { lat: -29.575582642019928, lng: 31.114601953254276 };
        const ballitoLocation = { lat: -29.5148, lng: 31.2088 };

        const isAddressInBallito = (address) => {
            const distance = getDistanceInKm(address.lat, address.lng, ballitoLocation.lat, ballitoLocation.lng);
            return distance <= 5;
        };

        const getDistanceInKm = (lat1, lng1, lat2, lng2) => {
            const toRad = (value) => value * Math.PI / 180;
            const R = 6371;

            const dLat = toRad(lat2 - lat1);
            const dLng = toRad(lng2 - lng1);

            const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
                Math.sin(dLng / 2) * Math.sin(dLng / 2);

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return R * c;
        };

        if (isAddressInBallito(address)) {
            return Promise.resolve(35);
        }

        const service = new window.google.maps.DistanceMatrixService();
        const origin = new window.google.maps.LatLng(restaurantLocation.lat, restaurantLocation.lng);
        const destination = new window.google.maps.LatLng(address.lat, address.lng);

        return new Promise((resolve, reject) => {
            service.getDistanceMatrix({
                origins: [origin],
                destinations: [destination],
                travelMode: 'DRIVING',
            }, (response, status) => {
                if (status === 'OK') {
                    const distanceInMeters = response.rows[0].elements[0].distance.value;
                    const distanceInKm = distanceInMeters / 1000;

                    if (distanceInKm <= deliveryRadius) {
                        resolve(0);
                    } else {
                        resolve(35);
                    }
                } else {
                    console.error('Distance Matrix request failed due to ' + status);
                    reject(status);
                }
            });
        });
    };

    const handleAddressConfirmation = () => {
        if (!address.streetName || !address.streetNumber || !address.suburb || !address.postalCode || !address.name || !address.phone) {
            alert('Please fill in all the delivery details, including address, name, and phone number.');
        } else if (address.lat && address.lng) {
            const latLng = {
                lat: parseFloat(address.lat),
                lng: parseFloat(address.lng),
            };
    
            calculateDeliveryFee(latLng)
                .then((fee) => {
                    setDeliveryFee(fee);
                    setFinalTotal(total + fee);
                    setAddressConfirmed(true);
                })
                .catch((error) => {
                    alert('Failed to calculate delivery fee. Please try again.');
                    console.error('Error calculating delivery fee:', error);
                });
        } else {
            alert('Please ensure all delivery details are filled out correctly.');
        }
    };

    const handleQuantityChange = (index, delta) => {
        updateItemQuantity(index, delta);
    };

    const handleRemoveItem = (index) => {
        setItemToRemove(index);
        setIsModalOpen(true);
    };

    const confirmRemoveItem = () => {
        if (itemToRemove !== null) {
            removeFromBasket(itemToRemove);
            setItemToRemove(null);
        }
        setIsModalOpen(false);
    };

    const cancelRemoveItem = () => {
        setItemToRemove(null);
        setIsModalOpen(false);
    };

    const item = itemToRemove !== null ? basket[itemToRemove] : null;

    const onDeliveryMethodSelect = (method) => {
        if (method === 'Online Payment') {
            window.location.href = 'https://your-payment-gateway.com/iframe';
        } else {
            submitOrder(method);
        }
    };

        const submitOrder = (paymentMethod) => {
            const orderDetails = {
                items: basket.map((item) => ({
                    id: item.id,
                    name: item.name,
                    size: item.size || '',
                    variant: item.variant || {},
                    quantity: item.quantity,
                })),
                total: finalTotal,
                deliveryAddress: {
                    ...address,
                    deliveryTime: deliveryTimeOption === 'ASAP' ? 'ASAP' : address.deliveryTime, // Include delivery time
                },
                deliveryFee: deliveryFee,
                paymentMethod,
                userId: user ? user.id : null,
            };

        setLoading(true);

        fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(orderDetails)
        })
            .then((res) => res.json())
            .then((data) => {
                setOrderStatus('Order placed successfully!');
                history.push(`/order-confirmation/${data.id}`);
                clearBasket();
            })
            .catch((err) => {
                setOrderStatus('Failed to place order. Please try again.');
                console.error('Error placing order:', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleAddMoreItems = () => {
        history.push('/');
    };

    const handlePlaceOrderClick = () => {
        if (!user) {
            setShowRegistrationSignInModal(true);
        } else {
            setDeliveryModalOpen(true);
        }
    };
    
    const handleRegistrationSignInSuccess = () => {
        setShowRegistrationSignInModal(false);
        setDeliveryModalOpen(true);
    };

    return (
        <div className="checkout-page">
            <h2>Checkout</h2>
            {basket.length === 0 ? (
                <p>Your basket is empty.</p>
            ) : (
                <div className="basket-summary">
                    <div className="basket-items">
                        {basket.map((item, index) => (
                            <div key={index} className="basket-item">
                                <div className="item-detail">
                                    <div className="item-info">
                                        <div>{item.name}</div>
                                        <div>{item.size || 'N/A'}</div>
                                        <div>{item.variant ? item.variant.name : 'None'}</div>
                                        <div>Price: R{item.variant?.price || item.price}</div>
                                    </div>
                                    <div className="item-quantity">
                                        <button onClick={() => handleQuantityChange(index, -1)}>-</button>
                                        <span>{item.quantity}</span>
                                        <button onClick={() => handleQuantityChange(index, 1)}>+</button>
                                    </div>
                                    <button className="remove-item" onClick={() => handleRemoveItem(index)}>Remove</button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="align-right">
                        <button className="ios-button" onClick={handleAddMoreItems}>Add More Items</button>
                        <p className="total-value">Food Total: <span>R{total}</span></p>
                        {addressConfirmed && (
                            <>
                                {deliveryFee === 0 ? (
                                    <p><strong>🎉 Delivery Fee: FREE! Enjoy this bonus treat on us! 🎉</strong></p>
                                ) : (
                                    <p>🚗 Delivery Fee: R{deliveryFee}. 🚗 <br /> We’d love to make delivery free, but it turns out petrol isn’t as cheap as we’d hoped. So, we’ll settle for a little fee instead of a magic carpet ride! Thanks for understanding and happy munching! 😄</p>
                                )}
                                <p className="total-value">Total Payment amount: <span>R{finalTotal}</span></p>
                            </>
                        )}
                    </div>
                    {!addressConfirmed ? (
                        <form onSubmit={handleSubmit}>
                            <h3>Great choice! We're excited to prepare your meal. Our kitchen is ready and the oil is hot. Please provide your delivery details to complete your order.</h3>
                            <input
                                type="text"
                                ref={addressInputRef}
                                placeholder="Enter your address"
                                onChange={handleChange}
                                name="address"
                                required
                            />
                            {errors.address && <p className="error error-address">Please enter a valid address.</p>}
                            <div className="other-fields">
                                <h4>Person collecting the delivery</h4>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Contact person name"
                                    value={address.name}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.name && <p className="error error-name">Please enter the contact person's name.</p>}
                                <br/><br/>
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Contact person number e.g. 0812345678"
                                    value={address.phone}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.phone && <p className="error error-phone">Please enter a valid contact number.</p>}
                            </div>
                            <br/>
                            <input
                                type="text"
                                placeholder="Any additonal delivery instructions"
                                onChange={handleChange}
                                name="deliveryNote"
                            />
                            <br/><br/>
                                    {/* Delivery Time Options */}
                                    {showDeliveryOptions && (
                                        <div className="delivery-time-options">
                                            <h4>Choose Delivery Time:</h4>
                                            <label>
                                                <input
                                                    type="radio"
                                                    className="delivery-time-radio" 
                                                    value="ASAP"
                                                    checked={deliveryTimeOption === 'ASAP'}
                                                    onChange={handleDeliveryOptionChange}
                                                />
                                                Deliver as soon as possible
                                            </label>
                                            {availableTimeSlots.length > 0 && (
                                                <>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            className="delivery-time-radio" 
                                                            value="Scheduled"
                                                            checked={deliveryTimeOption === 'Scheduled'}
                                                            onChange={handleDeliveryOptionChange}
                                                        />
                                                        Schedule delivery for later
                                                    </label>
                                                    {deliveryTimeOption === 'Scheduled' && (
                                                        <select
                                                            name="deliveryTime"
                                                            value={address.deliveryTime}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select a time slot</option>
                                                            {availableTimeSlots.map((slot, index) => (
                                                                <option key={index} value={slot}>
                                                                    {slot}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}

                            <button
                                className="ios-button-select"
                                type="button"
                                onClick={() => {
                                    if (!isOrderingAllowed) {
                                        alert('Ordering is currently closed. Please try again during open hours.');
                                    } else {
                                        handleAddressConfirmation();
                                    }
                                }}
                            >
                                Confirm Delivery Address Details
                            </button>
                        </form>
                    ) : (
                        <button
                            className="wide-button"
                            type="button"
                            onClick={handlePlaceOrderClick}
                        >
                            Place Order
                        </button>
                    )}
                </div>
            )}
            {orderStatus && <p className="order-status">{orderStatus}</p>}
            <DeliveryMethodModal
                isOpen={deliveryModalOpen}
                onDeliveryMethodSelect={onDeliveryMethodSelect}
                onClose={() => setDeliveryModalOpen(false)}
                loading={loading}
            />
            <ConfirmationModal
                isOpen={isModalOpen}
                onConfirm={confirmRemoveItem}
                onCancel={cancelRemoveItem}
                onClose={cancelRemoveItem}
                item={item}
            />
            <RegistrationSignInModal
                isOpen={showRegistrationSignInModal}
                onClose={() => setShowRegistrationSignInModal(false)}
                onSignIn={handleRegistrationSignInSuccess}
            />
        </div>
    );
    
}

export default Checkout;