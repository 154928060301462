// src/components/TermsAndConditions.js
import React from 'react';
import './TermsAndConditions.css';  // Import CSS for this component

const TermsAndConditions = () => {
    return (
        <div className="terms-container">
            <header>
                <h1>Terms and Conditions for Divineveg on 262</h1>
            </header>
            <main>
                <section>
                    <p><strong>Effective Date:</strong> August 01, 2024</p>
                    <h2>1. Introduction</h2>
                    <p>
                    Welcome to Divineveg on 262. These terms and conditions govern your use of our website and services. By accessing or using our website and placing an order, you agree to these terms. If you do not agree, please do not use our services.
                    </p>
                    <h2>2. Ordering Process</h2>
                    <p>Online Ordering: Orders must be placed through our website. We reserve the right to refuse or cancel any order at our discretion.
                     </p>
                    <p>Payment: Payment can be made in cash or by card upon delivery. Payment is due at the time of delivery.
                    </p>
                    <h2>3. Delivery Area</h2>
                    <p>
                     We provide delivery services within KZN South Africa, specifically in Durban areas including Tongaat, Seatides, Ballito, La Mercy, and Verulam. We do not guarantee delivery outside these areas.
                    </p>

                    <h2>4. Liability</h2>
                    <p>
                        <p>Food Safety: We are not liable for any illnesses, allergic reactions, or other health issues that may arise from consuming our food. Customers with specific dietary restrictions or allergies should exercise caution.
                    </p>
                        <p>Delivery Delays: We are not responsible for delays or late deliveries caused by factors beyond our control, including but not limited to traffic conditions, weather, or other unforeseen circumstances.
                        </p>
                        <p>Menu Accuracy: We strive to ensure that all information on our menu is accurate. However, we are not liable for any errors or omissions in the menu or product descriptions.
                        </p>
                            <p>Stock Availability: We cannot guarantee the availability of all menu items. In the event an item is out of stock, we will notify you and provide an alternative if possible.
                        </p>
                            <p>Price Changes: Prices and menu items may be subject to change without prior notice. We reserve the right to adjust pricing and menu items at our discretion.
                        </p>
                    </p>
                    <h2>5. User Conduct</h2>
                    <p>Prohibited Activities: Users agree not to engage in any unlawful, fraudulent, or abusive activities when using our website or services.
                    </p>
                    <p>Account Responsibility: If you create an account with us, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                    </p>
                    <h2>6. Intellectual Property</h2>
                    <p>
                        Ownership: All content, trademarks, and other intellectual property on our website are owned by or licensed to Divineveg on 262. Unauthorized use of our intellectual property is prohibited.
                    </p>

                    <h2>7. Limitation of Liability</h2>
                    <p>
                        Ownership: All content, trademarks, and other intellectual property on our website are owned by or licensed to Divineveg on 262. Unauthorized use of our intellectual property is prohibited.
                    </p>

                    <h2>8. Indemnification</h2>
                    <p>
                        You agree to indemnify and hold harmless Divineveg on 262, its affiliates, officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of or related to your use of our services or any violation of these terms.
                    </p>

                    <h2>9. Governing Law</h2>
                    <p>
                        These terms and conditions are governed by and construed in accordance with the laws of South Africa. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the South African courts.
                    </p>

                    <h2>10. Changes to Terms and Conditions</h2>
                    <p>
                        We reserve the right to modify these terms and conditions at any time. Any changes will be posted on our website and will be effective immediately upon posting.
                    </p>
                    <h2>11. Contact Us</h2>
                    <p>
                        For any questions or concerns regarding these terms and conditions, please contact us through our website or customer service.
                    </p>
                    <h2>12. Severability</h2>
                    <p>
                     If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.
                    </p>
                    <h2>13. Entire Agreement</h2>
                    <p>
                     These terms and conditions constitute the entire agreement between you and Divineveg on 262 regarding your use of our website and services, superseding any prior agreements.
                    </p>
                    
                    Business Name: Divineveg on 262 <br></br>
                    Established: 2024 <br></br>
                    <br></br>
                    By placing an order, you acknowledge that you have read, understood, and agreed to these terms and conditions.
                </section>
            </main>
            <footer>
                <p>&copy; 2024 Divineveg on 262. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default TermsAndConditions;



