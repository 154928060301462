import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UpdateMenuPage.css';

const UpdateMenuPage = () => {
    const [menuItems, setMenuItems] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [editingVariant, setEditingVariant] = useState(null);
    const [newItem, setNewItem] = useState({ name: '', description: '', price: '', image: '', category: '' });
    const [newVariant, setNewVariant] = useState({ name: '', price: '', size: '', menuItemId: '' });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState(null);
    const [deleteType, setDeleteType] = useState(''); // 'item' or 'variant'
    const [categorizedItems, setCategorizedItems] = useState({});

    useEffect(() => {
        refreshMenuItems();
    }, []);

    const refreshMenuItems = async () => {
        try {
            const response = await axios.get('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu');
            const menuItemsWithDefaults = response.data.map(item => ({
                ...item,
                variants: Object.keys(item.variants).reduce((acc, key) => acc.concat(item.variants[key]), [])
            }));
            
            // Categorize menu items
            const categorized = menuItemsWithDefaults.reduce((acc, item) => {
                if (!acc[item.category]) {
                    acc[item.category] = [];
                }
                acc[item.category].push(item);
                return acc;
            }, {});
    
            setMenuItems(menuItemsWithDefaults);
            setCategorizedItems(categorized);
        } catch (err) {
            console.error(err);
        }
    };

    const refreshVariantsForItem = async (menuItemId) => {
        try {
            const response = await axios.get(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu/${menuItemId}`);
            const updatedItem = response.data;
            updatedItem.variants = Object.keys(updatedItem.variants).reduce((acc, key) => acc.concat(updatedItem.variants[key]), []);
    
            setEditingItem(updatedItem); // Update the specific item being edited with new variants
        } catch (err) {
            console.error(err);
        }
    };
    

    const handleEditItemClick = (item) => {
        setEditingItem({
            ...item,
            variants: Object.keys(item.variants).reduce((acc, key) => acc.concat(item.variants[key]), []) // Flatten variants
        });
    };

    const handleEditVariantClick = (variant) => {
        setEditingVariant(variant);
    };

    const handleChange = (setter) => (e) => {
        setter(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleUpdateItem = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu/${editingItem.id}`, editingItem);
            alert('Menu item updated successfully!');
            setEditingItem(null);
            refreshMenuItems();
        } catch (err) {
            console.error(err);
            alert('Failed to update menu item');
        }
    };

    const handleUpdateVariant = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu/variant/${editingVariant.id}`, editingVariant);
            alert('Variant updated successfully!');
            setEditingVariant(null);
            await refreshMenuItems();
            await refreshVariantsForItem(editingItem.id);  // Refresh the item's variants
        } catch (err) {
            console.error(err);
            alert('Failed to update variant');
        }
    };

    const handleAddItem = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu', newItem);
            alert('Menu item added successfully!');
            setNewItem({ name: '', description: '', price: '', image: '', category: '' });
            refreshMenuItems();
        } catch (err) {
            console.error(err);
            alert('Failed to add menu item');
        }
    };

    const handleAddVariant = async (e) => {
        e.preventDefault();
        if (!newVariant.menuItemId) {
            alert('Menu Item ID is required for adding a variant');
            return;
        }
        try {
            await axios.post('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu/variant', newVariant);
            alert('Variant added successfully!');
            setNewVariant({ name: '', price: '', size: '', menuItemId: '' });
            await refreshMenuItems();
            await refreshVariantsForItem(newVariant.menuItemId);  // Refresh the item's variants
        } catch (err) {
            console.error(err);
            alert('Failed to add variant');
        }
    };

    const handleDeleteItem = (id) => {
        setDeleteTarget(id);
        setDeleteType('item');
        setShowConfirmModal(true);
    };

    const handleDeleteVariant = (id) => {
        setDeleteTarget(id);
        setDeleteType('variant');
        setShowConfirmModal(true);
    };

    const confirmDelete = async () => {
        setShowConfirmModal(false);
        try {
            if (deleteType === 'item') {
                await axios.delete(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu/${deleteTarget}`);
                alert('Menu item deleted successfully!');
                if (editingItem && editingItem.id === deleteTarget) setEditingItem(null);
            } else if (deleteType === 'variant') {
                await axios.delete(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu/variant/${deleteTarget}`);
                alert('Variant deleted successfully!');
                if (editingVariant && editingVariant.id === deleteTarget) setEditingVariant(null);
                await refreshVariantsForItem(editingItem.id);  // Refresh the item's variants
            }
            refreshMenuItems();  // Refresh after deletion
        } catch (err) {
            console.error(err);
            alert(`Failed to delete ${deleteType}`);
        }
    };

    const cancelDelete = () => {
        setShowConfirmModal(false);
    };

    return (
        <div className="update-menu-page">
            <div className="menu-section">
            <div className="add-item-form-container">
                    <h2>Add New Menu Item</h2>
                    <form onSubmit={handleAddItem} className="add-item-form">
                        {['name', 'description', 'price', 'image', 'category'].map(field => (
                            <div key={field} className="form-group">
                                <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
                                <input
                                    type={field === 'price' ? 'number' : 'text'}
                                    id={field}
                                    name={field}
                                    value={newItem[field]}
                                    onChange={handleChange(setNewItem)}
                                />
                            </div>
                        ))}
                        <button type="submit">Add Item</button>
                    </form>
                </div>
                <div className="menu-list-container">
                    <h2>Menu Items</h2>
                    {Object.keys(categorizedItems).map(category => (
                        <div key={category} className="category-section">
                            <h3>{category}</h3>
                            <ul className="menu-list">
                                {categorizedItems[category].map(item => (
                                    <li key={item.id} className="menu-item">
                                        <h3>ID: {item.id} ||</h3>
                                        <h3>NAME: {item.name} || </h3>
                                        <p>{item.description} || </p>
                                        <p>Price: R{item.price} ||</p>
                                        <button onClick={() => handleEditItemClick(item)}>Edit Item</button>
                                        <button onClick={() => handleDeleteItem(item.id)}>Delete Item</button>
                                    </li>
                                ))}
                            </ul>
                </div>
    ))}
</div>
            </div>

            {editingItem && (
                <div className="edit-item-container">
                    <h2>Edit Menu Item</h2>
                    <form onSubmit={handleUpdateItem} className="edit-item-form">
                        {['name', 'description', 'price', 'image', 'category'].map(field => (
                            <div key={field} className="form-group">
                                <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
                                <input
                                    type={field === 'price' ? 'number' : 'text'}
                                    id={field}
                                    name={field}
                                    value={editingItem[field]}
                                    onChange={handleChange(setEditingItem)}
                                />
                            </div>
                        ))}
                        <button type="submit">Update Item</button>
                    </form>

                    <h2>Manage Variants</h2>
                    <form onSubmit={handleAddVariant} className="add-variant-form">
                        <h3>Add New Variant</h3>
                        {['name', 'price', 'size', 'menuItemId'].map(field => (
                            <div key={field} className="form-group">
                                <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
                                <input
                                    type={field === 'price' ? 'number' : 'text'}
                                    id={field}
                                    name={field}
                                    value={newVariant[field]}
                                    onChange={handleChange(setNewVariant)}
                                />
                            </div>
                        ))}
                        <button type="submit">Add Variant</button>
                    </form>

                    <div className="variants-container">
                        <h3>Variants</h3>
                        <ul>
                            {editingItem.variants.map(variant => (
                                <li key={variant.id} className="variant-item">
                                    {variant.name} - R{variant.price} - {variant.size}
                                    <button onClick={() => handleEditVariantClick(variant)}>Edit</button>
                                    <button onClick={() => handleDeleteVariant(variant.id)}>Delete</button>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {editingVariant && (
                        <div className="edit-variant-container">
                            <h3>Edit Variant</h3>
                            <form onSubmit={handleUpdateVariant} className="edit-variant-form">
                                {['name', 'price', 'size'].map(field => (
                                    <div key={field} className="form-group">
                                        <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}:</label>
                                        <input
                                            type={field === 'price' ? 'number' : 'text'}
                                            id={field}
                                            name={field}
                                            value={editingVariant[field]}
                                            onChange={handleChange(setEditingVariant)}
                                        />
                                    </div>
                                ))}
                                <button type="submit">Update Variant</button>
                            </form>
                        </div>
                    )}
                </div>
            )}

            {showConfirmModal && (
                <div className="confirm-modal">
                    <div className="confirm-modal-content">
                        <h4>Are you sure you want to delete this {deleteType}?</h4>
                        <button onClick={confirmDelete}>Yes</button>
                        <button onClick={cancelDelete}>No</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpdateMenuPage;
