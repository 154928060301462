import React, { useEffect, useState } from 'react';
import './Carousel.css';

function Carousel() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const slides = [
        { image: process.env.PUBLIC_URL + '/bunnies.jpg', link: '/menu-item1' },
        { image: process.env.PUBLIC_URL + '/biryani.jpg', link: '/menu-item8' },
        { image: process.env.PUBLIC_URL + '/curry.jpg', link: '/menu-item9' },
        { image: process.env.PUBLIC_URL + '/toastie.jpg', link: '/menu-item2' },
        { image: process.env.PUBLIC_URL + '/potatobake.jpg', link: '/menu-item11' },
        { image: process.env.PUBLIC_URL + '/hotdog.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/pasta.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/bunnies.jpg', link: '/menu-item1' },
        { image: process.env.PUBLIC_URL + '/biryani.jpg', link: '/menu-item8' },
        { image: process.env.PUBLIC_URL + '/curry.jpg', link: '/menu-item9' },
        { image: process.env.PUBLIC_URL + '/toastie.jpg', link: '/menu-item2' },
        { image: process.env.PUBLIC_URL + '/potatobake.jpg', link: '/menu-item11' },
        { image: process.env.PUBLIC_URL + '/hotdog.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/pasta.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/bunnies.jpg', link: '/menu-item1' },
        { image: process.env.PUBLIC_URL + '/biryani.jpg', link: '/menu-item8' },
        { image: process.env.PUBLIC_URL + '/curry.jpg', link: '/menu-item9' },
        { image: process.env.PUBLIC_URL + '/toastie.jpg', link: '/menu-item2' },
        { image: process.env.PUBLIC_URL + '/potatobake.jpg', link: '/menu-item11' },
        { image: process.env.PUBLIC_URL + '/hotdog.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/pasta.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/bunnies.jpg', link: '/menu-item1' },
        { image: process.env.PUBLIC_URL + '/biryani.jpg', link: '/menu-item8' },
        { image: process.env.PUBLIC_URL + '/curry.jpg', link: '/menu-item9' },
        { image: process.env.PUBLIC_URL + '/toastie.jpg', link: '/menu-item2' },
        { image: process.env.PUBLIC_URL + '/potatobake.jpg', link: '/menu-item11' },
        { image: process.env.PUBLIC_URL + '/hotdog.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/pasta.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/bunnies.jpg', link: '/menu-item1' },
        { image: process.env.PUBLIC_URL + '/biryani.jpg', link: '/menu-item8' },
        { image: process.env.PUBLIC_URL + '/curry.jpg', link: '/menu-item9' },
        { image: process.env.PUBLIC_URL + '/toastie.jpg', link: '/menu-item2' },
        { image: process.env.PUBLIC_URL + '/potatobake.jpg', link: '/menu-item11' },
        { image: process.env.PUBLIC_URL + '/hotdog.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/pasta.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/bunnies.jpg', link: '/menu-item1' },
        { image: process.env.PUBLIC_URL + '/biryani.jpg', link: '/menu-item8' },
        { image: process.env.PUBLIC_URL + '/curry.jpg', link: '/menu-item9' },
        { image: process.env.PUBLIC_URL + '/toastie.jpg', link: '/menu-item2' },
        { image: process.env.PUBLIC_URL + '/potatobake.jpg', link: '/menu-item11' },
        { image: process.env.PUBLIC_URL + '/hotdog.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/pasta.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/bunnies.jpg', link: '/menu-item1' },
        { image: process.env.PUBLIC_URL + '/biryani.jpg', link: '/menu-item8' },
        { image: process.env.PUBLIC_URL + '/curry.jpg', link: '/menu-item9' },
        { image: process.env.PUBLIC_URL + '/toastie.jpg', link: '/menu-item2' },
        { image: process.env.PUBLIC_URL + '/potatobake.jpg', link: '/menu-item11' },
        { image: process.env.PUBLIC_URL + '/hotdog.jpg', link: '/menu-item7' },
        { image: process.env.PUBLIC_URL + '/pasta.jpg', link: '/menu-item7' },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [slides.length]);

    const isLargeScreen = window.innerWidth >= 1024;

    return (
        <div className={`carousel ${isLargeScreen ? 'large-screen' : ''}`}>
            <div className="carousel-inner" style={{ transform: `translateX(-${currentSlide * (100 / (isLargeScreen ? 3 : 1))}%)` }}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className="carousel-slide"
                        style={{ flex: `0 0 ${isLargeScreen ? '33.33%' : '100%'}` }}
                    >
                        <a href={slide.link}>
                            <img src={slide.image} alt={`Slide ${index + 1}`} />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Carousel;
