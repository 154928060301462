import React from 'react';
import './DeliveryMethodModal.css';

const DeliveryMethodModal = ({ isOpen, onDeliveryMethodSelect, onClose, loading }) => {
    if (!isOpen) return null;

    const handleMethodSelection = (method) => {
        onDeliveryMethodSelect(method);
    };

    return (
        <div className="delivery-method-modal">
            <div className="modal-content">
                <button className="close-modal-2" onClick={onClose}>x</button>
                <h3>Select Delivery Method</h3>
                <button
                    onClick={() => handleMethodSelection('cash')}
                    className="ios-button"
                >
                    {loading ? <span className="loading-icon ios-button"></span> : 'Pay on Delivery (Cash)'}
                </button>
                <button
                    onClick={() => handleMethodSelection('card')}
                    className="ios-button"
                >
                    {loading ? <span className="loading-icon ios-button"></span> : 'Pay on Delivery (Card)'}
                </button>
                <button
                    onClick={() => handleMethodSelection('Online Payment')}
                    className="ios-button"
                    disabled
                >
                    Online Payment
                    <span className="coming-soon"> (Coming Soon)</span>
                </button>
            </div>
        </div>
    );
};

export default DeliveryMethodModal;
