import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useHistory , useLocation} from 'react-router-dom';
import './Register.css';

function RegisterForm({ onSuccess }) {
    const { register } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);
    const [error, setError] = useState('');
    const history = useHistory();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }
        try {
            const result = await register(username, password);
            if (result.success) { // Adjust based on your API response
                // Notify parent component of successful registration
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }

                // Redirect based on the current location
                const redirectTo = location.pathname === '/checkout' ? '/checkout' : '/';
                history.push(redirectTo);
            } else {
                setError(result.message || 'Registration failed. Please try again.');
            }
        } catch (error) {
            console.error('Failed to register', error);
            setError('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="register-container">
            <form onSubmit={handleSubmit}>
                <h2>Register</h2>
                <input
                    type="text"
                    placeholder="Any username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                />
                <div className="password-container">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? 'Hide' : 'Show'}
                    </button>
                </div>
                <div className="password-container">
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button
                        type="button"
                        className="toggle-password"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                        {showConfirmPassword ? 'Hide' : 'Show'}
                    </button>
                </div>
                {error && <div className="error-message">{error}</div>}
                <button type="submit" className="register-btn">Register</button>
            </form>
        </div>
    );
}

export default RegisterForm;