import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <p>&copy; 2024 Divineveg on 262. All Rights Reserved.</p>
            <div className="footer-social-links">
                {/*<a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>*/}
                {/*<a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>*/}
                {/*<a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">TikTok</a>*/}
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </div>
        </footer>
    );
}

export default Footer;
