import React, { createContext, useState } from 'react';

export const BasketContext = createContext();

export const BasketProvider = ({ children }) => {
    const [basket, setBasket] = useState([]);

    const addToBasket = (item, variant) => {
        setBasket(prevBasket => {
            // Check if item already exists in the basket
            const existingItemIndex = prevBasket.findIndex(basketItem =>
                basketItem.id === item.id && basketItem.variant?.id === variant?.id
            );

            if (existingItemIndex > -1) {
                const updatedBasket = [...prevBasket];
                updatedBasket[existingItemIndex].quantity += 1;
                return updatedBasket;
            }

            return [...prevBasket, { ...item, variant, size: variant?.size || 'N/A', quantity: 1 }];
        });
    };

    const removeFromBasket = (index) => {
        setBasket(prevBasket => prevBasket.filter((_, i) => i !== index));
    };

    const updateItemQuantity = (index, delta) => {
        setBasket(prevBasket => {
            const newBasket = [...prevBasket];
            newBasket[index].quantity += delta;

            if (newBasket[index].quantity <= 0) {
                newBasket.splice(index, 1);
            }

            return newBasket;
        });
    };

    const clearBasket = () => {
        setBasket([]);
    };

    const total = basket.reduce((sum, item) => sum + ((item.variant?.price || item.price) * item.quantity), 0);

    return (
        <BasketContext.Provider value={{ basket, addToBasket, removeFromBasket, updateItemQuantity, total, clearBasket }}>
            {children}
        </BasketContext.Provider>
    );
};
