import { useState } from 'react';

const useCheckoutForm = (initialValues) => {
    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues(prevValues => ({ ...prevValues, [name]: value }));
    };


    const validate = () => {
        const errors = {};
        if (!values.addressType) errors.addressType = 'Address type is required.';
        if (!values.streetNumber && (values.addressType === 'Standalone Private House' || values.addressType === 'Apartment/Complex')) errors.streetNumber = 'Street number is required.';
        if (!values.streetName && (values.addressType === 'Standalone Private House' || values.addressType === 'Apartment/Complex')) errors.streetName = 'Street name is required.';
        if (!values.suburb && (values.addressType === 'Standalone Private House' || values.addressType === 'Apartment/Complex')) errors.suburb = 'Suburb is required.';
        if (!values.postalCode && (values.addressType === 'Standalone Private House' || values.addressType === 'Apartment/Complex')) errors.postalCode = 'Postal code is required.';
        if (values.addressType === 'Apartment/Complex') {
            if (!values.apartmentName) errors.apartmentName = 'Apartment/Complex name is required.';
            if (!values.unitNumber) errors.unitNumber = 'Unit number is required.';
        }
        if (values.addressType === 'Business/Work') {
            if (!values.businessName) errors.businessName = 'Business name is required.';
            if (!values.businessEstate) errors.businessEstate = 'Business estate is required.';
        }
        if (!values.name) errors.name = 'Name is required.';
        if (!values.phone) errors.phone = 'Phone number is required.';
        if (!/^\d+$/.test(values.phone)) errors.phone = 'Phone number must be numeric.';
        if (values.postalCode && !/^\d{4}$/.test(values.postalCode)) errors.postalCode = 'Postal code must be exactly 4 digits.';

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            console.log('Form values:', values);
        }
    };

    return {
        values,
        errors,
        handleChange,
        handleSubmit
    };
};

export default useCheckoutForm;
