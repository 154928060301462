import React from 'react';
import './Section.css';

function Section() {
    return (
        <div className="section">
            <div className="intro">
                Welcome to Our Family Kitchen! Enjoy fresh, 
                <span className="highlight"> 100% vegetarian </span>, 
                home-cooked meals delivered right to your door. Prepared daily with the finest ingredients, we serve Tongaat, Seatides, La Mercy, Ballito, and surrounds. Taste the difference of purely vegetarian goodness!
            </div>
        </div>
    );
}

export default Section;
