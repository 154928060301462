import React, { useState, useEffect } from 'react';
import { useParams , Link } from 'react-router-dom';
import './OrderConfirmation.css'; // Create a CSS file for styling if needed

const OrderConfirmation = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [searchId, setSearchId] = useState('');
    const [searchResult, setSearchResult] = useState(null);

    useEffect(() => {
        if (orderId) {
            fetchOrderDetails(orderId);
            requestNotificationPermission();
        }
    }, [orderId]);

    const fetchOrderDetails = async (id) => {
        try {
            const response = await fetch(`https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/orders/${id}`);
            if (response.ok) {
                const data = await response.json();
                console.log('Fetched order data:', data); // Debugging line
                setOrder(data);
            } else {
                console.error('Failed to fetch order details');
            }
        } catch (error) {
            console.error('Error fetching order details:', error);
        }
    };

    const handleSearch = async () => {
        if (searchId) {
            await fetchOrderDetails(searchId);
        }
    };

    // Function to calculate the estimated delivery time
    const getEstimatedDeliveryTime = (createdAt) => {
        if (!createdAt) return 'Calculating...';
        const createdDate = new Date(createdAt);
        const oneHourInMilliseconds = 3600000; // 1 hour in milliseconds
        const estimatedDeliveryDate = new Date(createdDate.getTime() + oneHourInMilliseconds);

        // Return a formatted string
        return estimatedDeliveryDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Adjust formatting as needed
    };

    
    // Request notification permission from the user
    const requestNotificationPermission = async () => {
        if ('Notification' in window && 'serviceWorker' in navigator) {
            try {
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    subscribeUserToPush();
                }
            } catch (error) {
                console.error('Error requesting notification permission:', error);
            }
        } else {
            console.error('Browser does not support notifications or service workers.');
        }
    };

    // Subscribe the user to push notifications
    const subscribeUserToPush = async () => {
        try {
            const registration = await navigator.serviceWorker.ready;
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: '{PUBLIC_VAPID_KEY}'
            });

            // Send the subscription to your server and associate it with the user
            await saveSubscription(subscription);
        } catch (error) {
            console.error('Failed to subscribe the user to push notifications', error);
        }
    };

    // Send subscription to the backend to save it against the user
    const saveSubscription = async (subscription) => {
        try {
            const response = await fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/subscriptions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: order?.userId, // Ensure that this is available
                    subscription,
                }),
            });

            if (response.ok) {
                console.log('Subscription saved successfully');
            } else {
                console.error('Failed to save subscription');
            }
        } catch (error) {
            console.error('Error saving subscription:', error);
        }
    };

    return (
        <div className="order-confirmation">
            {orderId ? (
                <div>
                    <h3>Your order has been placed successfully. Our kitchen has just received your request.</h3>
                    <h3>Thank you for your support!</h3>
                    <h3>Your order number is: <strong>{orderId}</strong></h3>
                    {order ? (
                        <div>
                            <h4>Order Details</h4>
                            <p><strong>Amount to pay on delivery:</strong> R{order.total}</p>
                            <p><strong>Payment Method:</strong> {order.paymentMethod}</p>
                            <h4>Items:</h4>
                            <ul>
                                {order.items.map((item, index) => (
                                    <li key={index}>
                                        {item.name} - {item.variant.name} - {item.variant.size} x {item.quantity}
                                    </li>
                                ))}
                            </ul>
                            <h4>Delivery Address:</h4>
                            <p>{order.deliveryAddress.streetNumber} {order.deliveryAddress.streetName}, {order.deliveryAddress.suburb}, {order.deliveryAddress.postalCode}</p>
                            <h4>People to contact:</h4>
                            <p>{order.deliveryAddress.name} on {order.deliveryAddress.phone}</p>
                            {order?.deliveryAddress.deliveryTime && order.deliveryAddress.deliveryTime !== "ASAP" ? (
                            <>
                                <h4>Delivery Time Slot:</h4>  
                                <p>{order.deliveryAddress.deliveryTime}</p>  
                            </>
                        ) : (
                            <>
                                <h4>Estimated Delivery Time:</h4>
                                <p>{getEstimatedDeliveryTime(order?.createdAt)}</p>  
                                <p>Please allow us an hour as we prepare all meals from scratch. Some items may be quicker than others, so we may get it to you much sooner. We will contact you once the driver is on their way.</p>
                            </>
                        )}
                    </div>
                    ) : (
                        <p>Loading order details...</p>
                    )}
                    <h3>We offer catering services for work or personal gatherings. You can reach us at <a href="mailto:lilly.govender2907@gmail.com">lilly.govender2907@gmail.com</a> or <a href="tel:0818408383">084 811 4260</a></h3>
                
                            {/* Google Rating Section */}
                            <section className="rate-us">
                                <h3>Rate Us on Google</h3>
                                <p>
                                We would greatly appreciate it if you could take a moment to leave us a review on Google. Your feedback helps us improve and continue delivering exceptional service!
                                </p>
                                <a
                                    href="https://g.page/r/CWUOuJ4RvwaUEBM/review"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="google-rate-btn"
                                >
                                    Rate Us on Google
                                </a>
                            </section>
                
                
                </div>
            ) : (
                <div>
                    <h3>Search for your Order</h3>
                    <input
                        type="text"
                        value={searchId}
                        onChange={(e) => setSearchId(e.target.value)}
                        placeholder="Enter Order ID"
                    />
                    <button onClick={handleSearch}>Search</button>
                    {searchResult && (
                        <div>
                            <h4>Order Details</h4>
                            {/* Display search results */}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default OrderConfirmation;
