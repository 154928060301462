import React, { useEffect, useState, useContext, useRef } from 'react';
import { BasketContext } from '../contexts/BasketContext';
import './Menu.css';
import './Modal.css'; // Import the Modal CSS

function Menu() {
    const [menuItems, setMenuItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const { addToBasket } = useContext(BasketContext);
    const [isVariantMissing, setIsVariantMissing] = useState(false);

    const specialsRef = useRef(null);
    const fastFoodRef = useRef(null);
    const mainMealsRef = useRef(null);
    const dessertsRef = useRef(null);
    const sidesRef = useRef(null);

    // Fetch menu items
    useEffect(() => {
        fetch('https://ancient-woodland-54168-4972224bd3d0.herokuapp.com/api/menu')
            .then(res => res.json())
            .then(data => setMenuItems(data))
            .catch(err => console.error('Error fetching menu items:', err));
    }, []);

    const handleAddToBasket = (item) => {
        setSelectedItem(item);
        const variants = Object.values(item.variants || {}).flat();
        if (variants.length === 1) {
            setSelectedVariant(variants[0]);
        } else {
            setSelectedVariant(null);
        }
    };

    const handleConfirm = () => {
        if (selectedItem && selectedVariant) {
            addToBasket(selectedItem, selectedVariant);
            setSelectedItem(null);
            setSelectedVariant(null);
            setIsVariantMissing(false); // Reset missing variant state
        } else {
            setIsVariantMissing(true); // Set missing variant state
        }
    };

    const handleVariantSelect = (variant) => {
        setSelectedVariant(variant);
        setIsVariantMissing(false); // Clear the warning when a variant is selected
    };

    const categorizedItems = {
        specials: menuItems.filter(item => item.category === 'specials'),
        fastFood: menuItems.filter(item => item.category === 'fast food'),
        mainMeals: menuItems.filter(item => item.category === 'main meals'),
        desserts: menuItems.filter(item => item.category === 'desserts'),
        sides: menuItems.filter(item => item.category === 'sides')
    };

    const renderVariantsBySize = (item) => {
        const variantsBySize = item.variants || {}; // Assuming this is an object where keys are sizes
    
        const variantGroups = {};
    
        Object.entries(variantsBySize).forEach(([size, variants]) => {
            if (variants.length === 0) return;
    
            variantGroups[size] = {
                lowestPrice: Infinity,
                variants: []
            };
    
            variants.forEach(variant => {
                if (variant.price < variantGroups[size].lowestPrice) {
                    variantGroups[size].lowestPrice = variant.price;
                }
                variantGroups[size].variants.push(variant);
            });
        });
    
        const sortedSizes = Object.entries(variantGroups).sort((a, b) => {
            return a[1].lowestPrice - b[1].lowestPrice;
        });
    
        return sortedSizes.map(([size, group]) => {
            const sortedVariants = group.variants.sort((a, b) => a.price - b.price);
    
            return (
                <div key={size} className="variant-size-group">
                    <h4>{size.charAt(0).toUpperCase() + size.slice(1)}</h4> 
                    {sortedVariants.map(variant => (
                        <div key={variant.id} className="variant-option" style={{ border: isVariantMissing && !selectedVariant ? '2px solid red' : 'none' }}>
                            <input
                                type="radio"
                                id={`variant-${variant.id}`}
                                name="variant"
                                value={variant.name}
                                checked={selectedVariant && selectedVariant.id === variant.id}
                                onChange={() => handleVariantSelect(variant)}
                            />
                            <label htmlFor={`variant-${variant.id}`}>
                                {variant.name} - R {variant.price}
                            </label>
                        </div>
                    ))}
                </div>
            );
        });
    };

    const renderMenuItems = (items) => (
        items.map(item => (
            <div
                key={item.id}
                className="menu-item"
                onClick={() => handleAddToBasket(item)}
                style={{ cursor: 'pointer' }}
            >
                <div className="menu-item-info">
                    <h3>{item.name}</h3>
                    <div className="desc">{item.description}</div>
                    <p>From <strong>R{item.price}</strong></p>
                    <button
                        className="ios-button"
                        onClick={(e) => { 
                            e.stopPropagation(); 
                            handleAddToBasket(item);
                        }}
                    >
                        Buy Now
                    </button>
                </div>
                <div className="menu-item-image">
                    <img src={item.image} width="100" height="100" alt={item.name} />
                </div>
            </div>
        ))
    );

    const scrollToSection = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="menu-container">
            <div className="menu-header">
                <button onClick={() => scrollToSection(specialsRef)}>Specials</button>
                <button onClick={() => scrollToSection(fastFoodRef)}>Fast Food</button>
                <button onClick={() => scrollToSection(mainMealsRef)}>Main Meals</button>
                <button onClick={() => scrollToSection(dessertsRef)}>Desserts</button>
                <button onClick={() => scrollToSection(sidesRef)}>Sides</button>
            </div>

            <div className="menu" id="specials" ref={specialsRef}>
                <h2>Specials for the day!</h2>
                {renderMenuItems(categorizedItems.specials)}
            </div>

            <div className="menu" id="fast-food" ref={fastFoodRef}>
                <h2>Fast Food</h2>
                {renderMenuItems(categorizedItems.fastFood)}
            </div>

            <div className="menu" id="main-meals" ref={mainMealsRef}>
                <h2>Main Meals</h2>
                {renderMenuItems(categorizedItems.mainMeals)}
            </div>

            <div className="menu" id="desserts" ref={dessertsRef}>
                <h2>Desserts</h2>
                {renderMenuItems(categorizedItems.desserts)}
            </div>

            <div className="menu" id="sides" ref={sidesRef}>
                <h2>Sides</h2>
                {renderMenuItems(categorizedItems.sides)}
            </div>

            {selectedItem && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        {renderVariantsBySize(selectedItem)}
                        {isVariantMissing && <p className="warning-message">Please select an option before adding to basket.</p>}
                        
                        <div className="modal-buttons">
                            <button className="modal-button confirm" onClick={handleConfirm}>Add to Basket</button>
                            <button className="modal-button cancel" onClick={() => {
                                setSelectedItem(null);
                                setIsVariantMissing(false); 
                            }}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Menu;
